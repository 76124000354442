/* eslint-disable @typescript-eslint/no-explicit-any */

export const ROUTES = {
  top: () => `/`,
  author: (id: number) => `/author/${id}`,
  supplierApply: {
    index: () => `/supplier`,
  },
  supplier: (id: number) => {
    return {
      profile: () => `/supplier/${id}`,
      message: {
        login: (params?: QueryParams) =>
          withQuery(`/supplier/${id}/message/login`, params),
        signup: (params?: QueryParams) =>
          withQuery(`/supplier/${id}/message/signup`, params),
        form: (params?: QueryParams) =>
          withQuery(`/supplier/${id}/message`, params),
      },
    };
  },
  about: () => `/about`,
  contact: () => `/contact`,
  press: () => `/press`,
  faq: () => `/faq`,
  login: () => `/login`,
  terms: () => `/terms`,
  privacy: () => `/privacy-policy`,
  cancellationPolicy: () => `/cancellation-policy`,
  bookingProcess: () => `/booking-process`,
  auth: {
    bookings: (bookingId: number) => {
      return {
        index: () => `/auth/bookings/${bookingId}`,
        detailCustomer: () => `/auth/bookings/${bookingId}?isCustomer=true`,
        checkout: () => `/auth/bookings/${bookingId}/checkout`,
        edit: () => `/auth/bookings/${bookingId}/edit`,
        cancel: () => `/auth/bookings/${bookingId}/cancel`,
        receipt: () => `/auth/bookings/${bookingId}/receipt`,
        review: () => `/auth/bookings/${bookingId}/review`,
        thanks: () => `/auth/bookings/${bookingId}/thanks`,
        recommended: () => `/auth/bookings/${bookingId}/recommended`,
      };
    },
    messages: {
      index: () => `/auth/messages`,
      detail: (messageId: number) => `/auth/messages/${messageId}`,
    },
  },
  blog: {
    index: () => `/blog`,
    category: (category: string) => `/blog/category/${category}`,
    company: () => `/blog/company`,
    guides: () => `/blog/guides`,
    search: (params: QueryParams) => withQuery(`/blog/search`, params),
  },
  country: (country: string) => {
    return {
      index: () => `/${country}`,
      blog: () => `/${country}/blog`,
      tours: () => `/${country}/tours`,
      onlineExperiences: () => `/${country}/online-experiences`,
      cars: () => `/${country}/cars`,
      guides: () => `/${country}/guides`,
      destinations: () => `/${country}/destinations`,
      tour: (tourSlug: string) => {
        return {
          index: () => `/${country}/tour/${tourSlug}`,
          checkout: {
            form: (params?: QueryParams) =>
              withQuery(`/${country}/tour/${tourSlug}/checkout/`, params),
            login: (params?: QueryParams) =>
              withQuery(`/${country}/tour/${tourSlug}/checkout/login`, params),
            signup: (params?: QueryParams) =>
              withQuery(`/${country}/tour/${tourSlug}/checkout/signup`, params),
          },
        };
      },
      city: (city: string) => {
        return {
          index: () => `/${country}/${city}`,
          blog: () => `/${country}/${city}/blog`,
          tours: () => `/${country}/${city}/tours`,
          onlineExperiences: () => `/${country}/${city}/online-experiences`,
          cars: () => `/${country}/${city}/cars`,
          guides: () => `/${country}/${city}/guides`,
        };
      },
    };
  },
};

type QueryParams = Record<string, any>;

export const withQuery = (path: string, query?: QueryParams): string => {
  const basePath = path.includes("?") ? path.split("?")[0] : path;
  const baseQueryParams = path.includes("?") ? path.split("?")[1] : undefined;

  const url = new URLSearchParams(baseQueryParams);

  if (query) {
    Object.keys(query).forEach((key) => {
      const value = query[key];

      if (value && query[key] != undefined) {
        if (Array.isArray(value)) {
          url.delete(key);
          value.forEach((v) => url.append(key, String(v)));
        } else {
          url.set(key, String(query[key]));
        }
      }
    });
  }

  if (!query && baseQueryParams === undefined) {
    return basePath;
  }

  return `${basePath}?${url.toString()}`;
};

export const convertUrlParamsToQueryParam = (urlParams: URLSearchParams) => {
  return Object.fromEntries(urlParams.entries());
};
